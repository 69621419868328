import useStatefulCookie from "./useStatefulCookie";

const useEmailVerificationTimerCookie = () => {
	const emailVerificationTimerCookie = useStatefulCookie<string | null>("emailVerificationTimerV2", {
		expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
		path: "/"
	});

	return { emailVerificationTimerCookie };
};

export default useEmailVerificationTimerCookie;
